<template>
  <div class="sectionne first-sectionne s-first">
    <video class="bg-video" width="100%" autoplay loop muted>
      <source src="../css/assets/videos/Inicioacallis.webm" type="video/webm">
      Your browser can't show this content.
    </video>
  </div>
          <div class="sectionne">
            <h1 class="sectionne-header">SERVICIOS</h1>
            <div class="servicios">
                <img src="../css/assets/imagenessitioAcallis/boton diseño grafico-min.png"/>
                <img src="../css/assets/imagenessitioAcallis/boton marketing-min.png"/>
                <img src="../css/assets/imagenessitioAcallis/boton coaching-min.png"/>
                <img src="../css/assets/imagenessitioAcallis/Recurso 28-min.png"/>
                <img src="../css/assets/imagenessitioAcallis/boton portafolio-min.png"/>
            </div>
        </div>
        <div class="sectionne sec-cotiza">
            <img class="sectionne-cotiza" src="../css/assets/imagenessitioAcallis/sinergia-min.jpg">
            <h3>Hagamos sinergia &<br>Empoderemos tu marca</h3>
            <a href="contacto" class="btn-cotiza-ya">¡COTIZA YA!</a>
        </div>
        <div class="sectionne shader-section" style="background-color: #E9EAEB;">
            <h1 class="sectionne-header" style="color:#5F5F5F">¿QUÉ NECESITAS?</h1>
            <div class="sectioneer">
                <img class="imgs-serv" src="../css/assets/imagenessitioAcallis/paquetes01-min.png"/>
                <img class="imgs-serv" src="../css/assets/imagenessitioAcallis/paquetes02-min.png"/>
                <img class="imgs-serv" src="../css/assets/imagenessitioAcallis/paquetes03-min.png"/>
                <img class="imgs-serv" src="../css/assets/imagenessitioAcallis/paquetes04-min.png"/>
            </div>
            <div class="sectioneer-2-1">
                <a href="portafolio" class="a-right btn-portfolio">
                    <img src="../css/assets/imagenessitioAcallis/boton ver portafolio-min.png"/>
                </a>
            </div>
            <div class="sectioneer-2-2">
                <a href="contacto" class="a-left btn-cotizar">
                    <img src="../css/assets/imagenessitioAcallis/boton cotizarya-min.png"/>
                </a>
            </div>
        </div>
        <div class="sectionne astronata" style="background-color: #FBF9FC;">
            <div class="full-stronaut">
                <img class="ground" src="../css/assets/imagenessitioAcallis/planetas rosas.png"/>
                <img class="control" src="../css/assets/imagenessitioAcallis/planeta rosa grnade.png"/>
                <img class="major-tom" src="../css/assets/imagenessitioAcallis/astronauta2.png"/>
            </div>
            <div class="side-texto">
                <div class="astro-text">
                    <h1>¿Listo para llevar tu marca<br>al siguiente nivel?</h1>
                    <p>Sabías que al desarrollar tu marca personal, generas confianza y credibilidad con tu audiencia.</p>
                </div>
                <div class="planet-text first-planet">
                    <img src="../css/assets/imagenessitioAcallis/plant1-min.png"/>
                    <p>Somos la Agencia <span>#1</span> con Coaching</p>
                </div>
            </div>
            <div class="bottom-texto">
                <div class="planet-text bot-planet planet1">
                    <img src="../css/assets/imagenessitioAcallis/plant3-min.png"/>
                    <p><span>+77</span>Clientes<br>Felices</p>
                </div>
                <div class="planet-text bot-planet planet2">
                    <img src="../css/assets/imagenessitioAcallis/plant2-min.png"/>
                    <p><span>+333</span><br>Diseños<br>Realizados</p>
                </div>
                <div class="planet-text bot-planet planet3">
                    <p style="text-align: right;"><span>+111</span><br>Proyectos<br>Concretados</p>
                </div>
            </div>
        </div>
           <div class="sectionne first-sectionne s-first">
            <video ref="videoRef" class="bg-video" src="../css/assets/videos/sitios.mp4" autoplay loop playsinline></video>
        </div>
        <div class="sectionne">
            <img class="sectionne-cotiza" src="../css/assets/imagenessitioAcallis/conocenos-min.jpg"/>
            <div class="text-over-picture">
                <h1>Conocer nuestro trabajo:</h1>
                <p>Diseño y Rediseño de Marca Corporativa.</p>
                <p>Diseño y Desarrollo de marca de Emprendimiento.</p>
                <p>Talleres para emprendedores y negocios</p>
                <a href="portafolio" class="btn-leermas">LEER MÁS</a>
            </div>
            
        </div>

  
  
</template>

<style>
@keyframes floating {
	0% {
		transform: translate(0%,0%);	
	}
	25% {
		transform: translate(1.6666%,5%);	
	}	
	50% {
		transform: translate(3.3333%,1.6666%);	
	}	
	75% {
		transform: translate(0%,5%);	
	}	
	100% {
		transform: translate(0%,0%);
	}			
}

.credesarrollamos{
    width: 95%;
    margin-left: 2.5%;
    float: left;
    margin-top: 124px;
    margin-bottom: 46px;
}
.credesarrollamos .textu{
    float: left;
    width: 50%;
    overflow-x: hidden;
}
.credesarrollamos .textu h1{
    font-size: 32px;
}
.credesarrollamos .womann{
    float: left;
    width: 48%;
    overflow-x: hidden;
    border-left: 5px solid #5B5B5F;
}
.credesarrollamos .womann img{
    width: 50%;
}


.bg-video{
    width: 100%;
}
.s-first,.shader-section {
    box-shadow: 0px -5px 61px -15px rgba(0,0,0,0.4);
    transform: scale(1);
}
.sectionne-header{
    font-family: Barlow;
    color: #B29774;
    letter-spacing: 2px;
    width: 100%;
    text-align: center;
    font-size: 42px;
    margin-top: 64px;
    float: left;
}
.servicios{
    width: 85%;
    margin-left: 7.5%;
    float: left;
    padding-bottom: 28px;
}
.servicios img{
    width: 15%;
    float: left;
    margin:0 2.5%;
}
.sectionne-cotiza{
    width: 100%;
    float: left;
}
.btn-cotiza-ya{
    background-color: #F15E21;
    color: white;
    font-family: Barlow;
    text-decoration: none;
    font-size: 30px;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: -195px;
    float: left;
    padding: 0 12px 4px;
    border-radius: 12px;
    font-weight: bold;
    letter-spacing: 3px;
}
.sec-cotiza h3{
    color: white;
    font-family: Barlow;
    width: 100%;
    text-align: center;
    margin-top: -451px;
    float: left;
    font-size: 96px;
    font-weight: bold;
    line-height: 86px;
}
.imgs-serv{
    width: 20%;
    float: left;
    margin: 0 2.5%;
    transition: 0.3s ease-out;
}
.imgs-serv:hover{
    transform: scale(1.1);
}
.sectioneer{
    width: 90%;
    float: left;
    margin-left: 5%;
}
.sectioneer-2-1{
    width: 45%;
    float: left;
    margin-left: 5%;
}
.sectioneer-2-2{
    width: 45%;
    float: left;
}
.a-left{
    float: left;
}
.a-right{
    float: right;
}
.btn-portfolio img,.btn-cotizar img{
    height: 114px;    
}
.btn-portfolio,.btn-cotizar{
    transition: 0.3s ease-out;
    cursor: pointer;
}
.btn-portfolio{
    margin-right: 5%;
}
.btn-cotizar{
    margin-left: 5%;
}
.btn-portfolio:hover{
    transform: scale(1.1);
    filter: brightness(150%);
}
.btn-cotizar:hover{
    transform: scale(1.1);
    filter: brightness(150%);
}
.the-astronaut{
    width: 40%;
    margin-left: 5%;
    float: left;
    animation-name: floating;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
    animation-duration: 15s;
}
.side-texto{
    float: left;
    width: 22%;
    margin-left: 9%;
    margin-top: 120px;
}
.astro-text{
    float: left;
    width: 100%;
}
.astro-text h1{
    color: #434142;
    font-family: DIN;
    text-align: center;
}
.astro-text p{
    font-family: Barlow;
    color: #434142;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.8px;
    text-align: center;
    margin-left: 3%;
}
.planet-text{
    float: left;
    width: 300px;
    animation-name: floating;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
    animation-duration: 10s;
}
.planet-text img{
    width: 32%;
    float: left;
}
.planet-text p{
    width: 68%;
    float: left;
    font-family: 'DIN';
    color: #434142;
    font-size: 32px;
    margin-top: -16px;
    line-height: 34px;
}
.planet-text p span{
    color: black;
    font-size: 44px;
}
.first-planet{
    margin-left: 15%;
    margin-top: 76px;
}
.bottom-texto{
    float: left;
    width: 72%;
    margin-left: 14%;
    margin-top: -56px;
    transform: scale(1);
    padding-bottom: 36px;
}
.bot-planet{
    float: right;
    width: 33%;
    animation-name: floating;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
}
.bot-planet:nth-child(1){
    animation-duration: 11s;
}
.bot-planet:nth-child(2){
    animation-duration: 17s;
}
.bot-planet:nth-child(3){
    animation-duration: 14s;
}
.text-over-picture{
    width: 464px;
    float: left;
    margin-left: 20%;
    margin-top: -640px;
    color: white;
    font-family: DIN;
}
.text-over-picture h1{
    font-size: 40px;
}
.text-over-picture p{
    font-weight: lighter;
    width: 90%;
    font-size: 32px;
    font-family: Barlow;
}
.text-over-picture a{
    text-decoration: none;
    color: white;
    font-family: DIN;
    padding: 4px 12px;
    background: linear-gradient(0deg, #628E31, #9EB831);
    border-radius: 12px;
    margin-left: 50%;
    transform: translateX(-50%);
    float: left;
    transition: 0.3s;
    margin-top: 60px;
}
.text-over-picture a:hover{
    filter: brightness(75%);
}
.full-stronaut{
    width: 40%;
    margin-left: 5%;
    float: left;
}
.full-stronaut img{
    width: 100%;
}
.ground{
    animation-name: floating;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
    animation-duration: 19s;
}
.control{
    margin-top: -100%;
    animation-name: floating;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
    animation-duration: 15s;
}
.major-tom{
    margin-top: -100%;
    animation-name: floating;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
    animation-duration: 11s;
}

@media only screen and (max-width : 900px) {
    .bg-video{
        margin-top: 42px;
    }
    .servicios img{
        width: 45%;
    }
    .servicios img:last-child{
        margin-left: 25%;
    }
    .sec-cotiza h3{
        display: none;
    }
    .btn-cotiza-ya{
        margin-top: -85px;
    }
    .imgs-serv{
        width: 50%;
        margin: 0;
    }
    .sectioneer-2-2 img,.sectioneer-2-1 img{
        height: auto;
        width: 100%;
    }
    .astronata{
        background-color: #322046 !important;
    }
    .full-stronaut{
        width: 90%;
    }
    .full-stronaut img{
        filter: brightness(0.5);
    }
    .side-texto{
        width: 90%;
        margin-left: 5%;
        margin-top: -252px;
        transform: scale(1);
    }
    .side-texto h1,.side-texto p{
        color: white;
    }
    .first-planet{
        width: 70%;
        margin-top: 8px;
    }
    .text-over-picture{
        width: 90%;
        margin-left: 5%;
        margin-top: -210px;
    }
    .text-over-picture h1{
        font-size: 24px;
    }
    .text-over-picture p{
        font-size: 16px;
    }
    .btn-leermas{
        float: right !important;
        margin-top: 0 !important;
        margin-left: 0 !important;
        transform: none !important;
    }
    .sectionne{
        overflow-x: hidden;
    }
    .sectionne-cotiza{
        width: 128%;
        margin-left: -14%;
    }
    .planet-text p{
        color: #C1C8BC;
    }
    .planet-text p span{
        color: white;
    }
    .bottom-texto{
        width: 95%;
        margin-left: 2.5%;
        margin-top: 16px;
    }
    .planet1,.planet2,.planet3{
        width: 60%;
        margin: 0 !important;
    }
    .planet1,.planet3{
        margin-right: 40% !important;
    }
    .sectionne-header{
        margin-top: 22px;
    }
}
</style>
<script>
// @ is an alias to /src

</script>
